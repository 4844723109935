import styled from 'styled-components';

// const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
float: left;
margin-bottom: 25px;
width: 100%;
margin-right: 15px;
height: 100%;

  > div: first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;	

    > div: first-child {
      text-transform: capitalize;
      font-size: 18px;
      line-height: 21px;
      color: #0D4270;
      font-family: ${MediumFont};
    }
    > div: last-child {
      text-align: right;
      font-size: 18px;
      line-height: 24px;
      font-family: Rubik;
      color: #FD7175;
	  cursor: pointer;
    }
  }
  @media(max-width: 1200px) {
    width: 100%;
    margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;

const SubContainer = styled.div`
height: ${({ showAll }) => showAll ? 'auto' : '360px'};
overflow: ${({ showAll }) => showAll ? 'auto' : 'hidden'};
width: 100%;
background-color: #FFF;
box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);
border-radius: 4px;
>div{
	>div:nth-child(2){
	height: 100%;
    display: flex;
	padding-top: 10px;
    justify-content: center;
    flex-direction: column;
	>div:first-child{
		text-transform: capitalize;
    	height: 30%;
	}
	>div:nth-child(2){
		height: 70%;
		>div{
			width: 32px;
    	    height: 32px;
			img{
				width: 20px;
    		    height: 20px;			
			}
		}
	}
	}
}
`

const Card = styled.div`
width: 100%;
height: 90px;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #F0F0F0;
padding: 10px;
> div: first-child {
  width: 20%;
  > img {
    width: 60px;
	height: 60px;
	border-radius: 3px;
  }
}
> div: nth-child(2) {
  width: 50%;
  > div: first-child {
    font-size: 18px;
    line-height: 20px;
    color: #3A3A3A;
    font-family: 'Rubik-Medium';
    margin-bottom: 5px;
  }
}
> div:last-child {
  min-width: 100px;
  .addBuddy {
    background-color: #FD7175;
    outline: none;
    border: navajowhite;
    width: 100%;
    height: 30px;
    padding: 2px 10px;
    border-radius: 3px;
	cursor: pointer;
	display: flex;
    justify-content: center;
    align-items: center;
	>span {
		color: #FFF;
		font-size: 16px;
		font-family: 'Rubik-Regular';
		line-height: 2px;
	}
  }

  .pending{
		background-color:#f6b479;
		outline: none;
    border: navajowhite;
    width: 100%;
    height: 30px;
    padding: 2px;
    border-radius: 3px;
		cursor: pointer;
		display: flex;
    justify-content: center;
    align-items: center;
		>span{
			color: #FFF;
			font-size: 16px;
			font-family: 'Rubik-Regular';
			line-height: 2px;
		}
  }
}
	@media(max-width: 1260px) {
		> div: first-child {
			margin-right: 10px;
		}
	}
	@media(max-width: 1225px) and (min-width: 768px) {
		flex-flow: column;
		height: 220px;
		align-items: center;

		> div: nth-child(2) {
			display: flex;
			justify-content: center;
			flex-flow: column;
			width: 100%;
			align-items: center;
			> div: first-child {
				font-size: 18px;
				text-align: center;
			}
		}
		> div: last-child {
			width: 100%;
			> button {
				font-size: 14px;
			}
		}
	}
	@media(max-width: 350px){
		flex-flow: column;
		height: 220px;
		align-items: center;

		> div: nth-child(2) {
			display: flex;
			justify-content: center;
			flex-flow: column;
			width: 100%;
			align-items: center;
			> div: first-child {
				font-size: 18px;
				text-align: center;
			}
		}
		> div: last-child {
			width: 100%;
			> button {
				font-size: 14px;
			}
		}
	}
`;
const WellnessInterest = styled.div`
  display: flex;
  width: 100%;
  > div {
    width: 25px;
    height: 25px;
    background-color: rgba(151,151,151,0.11);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
		> img {
			width: 50%;
		}
	}
	@media(max-width: 1225px) and (min-width: 768px) {
		width: auto;
		> div {
			width: 35px;
			height: 35px;

			> img {
				width: 40%;
			}
		}
	}
	@media(max-width: 350px){
		width: auto;
		> div {
			width: 35px;
			height: 35px;

			> img {
				width: 40%;
			}
		}
	}
`;

const CardNew = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
// border-bottom: 1px solid #EAEAEA;
padding: 15px;
background: #FFFFFF;
border-radius: 6px;
margin-bottom: 5px;
> div: first-child {
  width: 60px;
  display: flex;
  margin: auto;
  margin-top: 0px;
  > img {
    width: 60px;
	height: 60px;
	border-radius: 3px;
  }
}
> div: nth-child(2) {
  width: calc(100% - 60px);
  padding-left: 15px;
  > div: first-child {
    font-size: 16px;
    line-height: 20px;
    color: #005C87;
    font-family: 'Rubik-Medium';
    margin-bottom: 5px;
  }

  > div: nth-child(3) {
>div{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	  .addBuddy {
	    background-color: #005c87;
	    outline: none;
	    // border: navajowhite;
	    min-width: 130px;;
	    height: 40px;
	    padding: 2px 10px;
	    border-radius: 3px;
		cursor: pointer;
		display: flex;
	    justify-content: center;
		align-items: center;
		// width: 50%;
		margin-right: 10px;
		>span {
			color: #FFF;
			font-size: 16px;
			line-height:20px;
			font-family: 'Rubik-Medium';
		}
	  }
	
	  .pending{
			background-color:#FFBF73;
			outline: none;
	    border: navajowhite;
		min-width: 130px;;
		// width: 50%;
		margin-right: 10px;
	    height: 40px;
	    padding: 2px;
	    border-radius: 3px;
		cursor: pointer;
		display: flex;
	    justify-content: center;
	    align-items: center;
			>span{
				color: #005C87;
				font-size: 16px;
				line-height:20px;
				font-family: 'Rubik-Medium';
				line-height: 2px;
			}
	  }
}

}


// > div:last-child {
//   min-width: 100px;
//   .addBuddy {
//     background-color: #FD7175;
//     outline: none;
//     border: navajowhite;
//     width: 100%;
//     height: 30px;
//     padding: 2px 10px;
//     border-radius: 3px;
// 	cursor: pointer;
// 	display: flex;
//     justify-content: center;
//     align-items: center;
// 	>span {
// 		color: #FFF;
// 		font-size: 16px;
// 		font-family: 'Rubik-Regular';
// 		line-height: 2px;
// 	}
//   }

//   .pending{
// 		background-color:#f6b479;
// 		outline: none;
//     border: navajowhite;
//     width: 100%;
//     height: 30px;
//     padding: 2px;
//     border-radius: 3px;
// 		cursor: pointer;
// 		display: flex;
//     justify-content: center;
//     align-items: center;
// 		>span{
// 			color: #FFF;
// 			font-size: 16px;
// 			font-family: 'Rubik-Regular';
// 			line-height: 2px;
// 		}
//   }
// }
	@media(max-width: 1260px) {
		> div: first-child {
			margin-right: 10px;
		}
	}
	@media(max-width: 1225px) and (min-width: 768px) {
		flex-flow: column;
		height: 220px;
		align-items: center;

		> div: nth-child(2) {
			display: flex;
			justify-content: center;
			flex-flow: column;
			width: 100%;
			align-items: center;
			> div: nth-child(1) {
				font-size: 18px;
				text-align: center;
			}
		}
		> div: nth-child(2) {
			width: 100%;
			> button {
				font-size: 14px;
			}
		}}
		
	
	@media(max-width: 350px){
		flex-flow: column;
		height: 220px;
		align-items: center;

		> div: nth-child(2) {
			display: flex;
			justify-content: center;
			flex-flow: column;
			width: 100%;
			align-items: center;
			> div: first-child {
				font-size: 18px;
				text-align: center;
			}
		}
		> div: last-child {
			width: 100%;
			> button {
				font-size: 14px;
			}
		}
	}
`;

const SubContainerNew = styled.div`
height: ${({ showAll }) => showAll ? 'auto' : 'auto'};
overflow: ${({ showAll }) => showAll ? 'auto' : 'hidden'};
width: 100%;
background-color: #FFF;
// box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);
border-radius: 4px;
>div{
	>div:nth-child(2){
	// height: 100%;
    display: flex;

    justify-content: center;
    flex-direction: column;
	>div:first-child{
		text-transform: capitalize;
    	height: 30%;
	}
	>div:nth-child(2){
		height: 70%;
		margin-bottom: 10px;
		>div{
			width: 32px;
    	    height: 32px;
			img{
				width: 18px;
    		    height: 18px;			
			}
		}
	}
	}
}
`

const WellnessInterestNew = styled.div`
  display: flex;
  width: 100%;
//   margin-bottom: 15px;
  > div {
    width: 30px;
    height: 30px;
    background-color: #005C870D;
    display: flex;
    justify-content: center;
    align-items: center;
	margin-right: 10px;
	border-radius: 3px;
		> img {
			width: 18px;
		}
	}

	@media(max-width: 350px){
		width: auto;
		> div {
			width: 35px;
			height: 35px;

			> img {
				width: 40%;
			}
		}
	}
`;

const RemoveButton= styled.div`
  max-width: 140px;
  height: 40px;
  border: 1px solid #005C8799;
  border-radius: 3px;
  display: flex;
  align-item:center;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  >span{
	font-family: Rubik-Medium;
	font-size: 16px;
	line-height: 20px;
	color: #005C8799;
	margin: auto;
  }
`;

const MainContainerNew = styled.div`
float: left;
width: 100%;
margin-right: 15px;
height: 100%;
border-top: 1px solid #EAEAEA;
margin-bottom: 25px;

  > div: first-child {
    width: 100%;
    display: flex;
    justify-content: space-between;
		align-items: center;
		margin-bottom: 15px;
		margin-top: 15px;	

    > div: first-child {
      text-transform: capitalize;
      font-size: 18px;
      line-height: 24px;
      color: #005C87;
      font-family: ${MediumFont};
    }
    > div: last-child {
      text-align: right;
      font-size: 16px;
      line-height: 20px;
      font-family: Rubik;
      color: #005C8799;
	  cursor: pointer;
    }
  }
  @media(max-width: 1200px) {
    width: 100%;
    margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;

const UserCard = styled.div`
  width:100%;
  display:flex;
  background:white;
  border-radius:3px;
  padding: 15px;
  flex-wrap:wrap;
  margin-bottom:5px;
  .firstCard{
	display:flex;
	width:100%;
	.image{
		width:60px;
		height:60px;
		>img{
		  width:60px;
		  height:60px;
		  border-radius:6px;
		}
	}
	.userDetails{
	   display:block;
	   margin: auto;
       margin-left: 15px;
	   .userName{
		font-family: Rubik-Medium;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;
		color:#005c87;	
		margin-bottom: 5px;	
		text-transform: capitalize;
	   }
	   .wellness{
		display:flex;
	   }	
	}
  }
  .buttonContainer{
	width:100%;
	margin-top:5px;
	display:flex;
	.addbutton{
		width:145px;
		height:40px;
		border-radius:3px;
		cursor:pointer;
		background:#005c87;
		font-family: Rubik-Medium;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: center;
        color:white;
		display:flex;
		justify-content:center;
		align-items:center;
		margin-right:10px;
	}
  }
`;

export {
  MainContainer, SubContainer, Card, WellnessInterest, CardNew, SubContainerNew, WellnessInterestNew, RemoveButton, MainContainerNew, UserCard
}
