/* eslint-disable no-console */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MainContainer, SubContainer, Card, WellnessInterest } from './styles';
import { ImageUrl } from '../../utils/constants';
import { isNull } from 'lodash';
import { sendFriendRequest} from '../../redux/actions';
import {Img} from 'react-image';
import {withTranslation} from 'react-i18next';

class SuggestionForYou extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      showAll : false,
      suggestionLists: props.suggestionList
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.suggestionList !== this.props.suggestionList){
      this.setState({suggestionLists: this.props.suggestionList});
    }
  }
  sendRequest = (id) => {
    const { showAddBuddyPopUp } = this.props;
    // sendFriendRequest(id, false);
    showAddBuddyPopUp(id);
  }

  handleShowAll = () => {
    this.setState((prev) => ({
      showAll : !prev.showAll
    }))
  }

  cancelRequest = (id, status) => {
    const { showAddBuddyPopUp2 } = this.props;
    showAddBuddyPopUp2(id, status);
  }

  render() {
    const { suggestionLists } = this.state;
    const {t} = this.props;
    return(
      <div>
        {
          suggestionLists && suggestionLists.length === 0 ? null :
            (
              <MainContainer>
                <div>
                  <div>
                    {t("Buddies Recommended")}
                  </div>
                  <div onClick={this.handleShowAll}>
                    {t("See All")}
                  </div>
                </div>
                <SubContainer showAll={this.state.showAll}>
                  {!isNull(suggestionLists) && suggestionLists.length > 0 ? suggestionLists.map((users) => (
                    users.map((user, index) => (
                      <Card key={index}>
                        <div>
                          <Img
                            alt={user.name}
                            src={`${ImageUrl}/${user.profile_image}`}
                            unloader={
                              <Img
                                src='/public/images/neutral_avatar.svg'
                                alt='avatar'
                              />
                            }
                          />
                        </div>
                        <div>
                          <div>{user.name.toLowerCase()}</div>
                          <WellnessInterest>
                            {user && user.wellness_interest ? user.wellness_interest.slice(0,5).map((interest, index) => (
                              <div key={index}>
                                <img src={`${ImageUrl}/${interest.interest_image}`} />
                              </div>
                            ))
                              : null
                            }
                          </WellnessInterest>
                        </div>
                        <div>
                          {
                            user.request_status === "Pending" ? <div className="pending" onClick={() => this.cancelRequest(user.uid, user.request_status)}><span>{t('Pending')}</span></div> :
                              <div className="addBuddy" onClick={() => this.sendRequest(user.uid, user.request_status)}><span>{t("Add Buddy")}</span></div>
                          }
                        </div>
                      </Card>
                    ))
                  ))
                    : null
                  }
                </SubContainer>
              </MainContainer>
            )
        }
      </div>
    );
  }
}

SuggestionForYou.propTypes = {
  suggestionList: PropTypes.array,
  history: PropTypes.object.isRequired,
  sendSuggestionFriendRequest: PropTypes.func,
  sendFriendRequest : PropTypes.func,
  showAddBuddyPopUp: PropTypes.func,
  showAddBuddyPopUp2: PropTypes.func,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});
const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestionForYou));
