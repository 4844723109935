/* eslint-disable no-console */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WellnessInterestNew, RemoveButton, MainContainerNew, UserCard } from './styles';
import { ImageUrl } from '../../utils/constants';
import { isNull } from 'lodash';
import { isEmpty } from 'lodash';
import { isUndefined } from 'lodash';
import { sendFriendRequest, getRejectFriendSuggestion } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import { LoaderContainer } from "../Content/styles";
import Loading from '../Loading';

class SuggestionForYouSocial extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      suggestionLists: props.suggestionList,
      initialLength: 3,
      temp: 0
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.suggestionList !== this.props.suggestionList) {
      this.setState({ suggestionLists: this.props.suggestionList });
    }
  }
  sendRequest = (id, status) => {
    const { showAddBuddyPopUp } = this.props;
    showAddBuddyPopUp(id, status);
  }

  rejectRequest = (id) => {
    const { getRejectFriendSuggestion } = this.props;
    getRejectFriendSuggestion(id);
  }

  handleShowAll = () => {
    this.setState((prev) => ({
      showAll: !prev.showAll,
    }))
    if (this.state.temp === 0) {
      this.setState({
        initialLength: this.state.suggestionLists[0].length,
        temp: 1
      })
    } else {
      this.setState({
        initialLength: 3,
        temp: 0
      })
    }
  }

  cancelRequest = (id, status) => {
    const { showAddBuddyPopUp2 } = this.props;
    showAddBuddyPopUp2(id, status);
  }

  fallBack = (e) => {
    e.target.src = "/public/images/neutral_avatar.svg";
  };

  render() {
    const { suggestionLists, initialLength } = this.state;
    const { t } = this.props;
    let usersData;
    let userDataShow;
    if (isUndefined(suggestionLists)) {
      return (<LoaderContainer><Loading /></LoaderContainer>)
    }
    if (!isNull(suggestionLists) && !isEmpty(suggestionLists)) {
      usersData = suggestionLists[0];
      userDataShow = usersData && usersData.slice(0, initialLength);
    }
    return (
      <div>
        {
          isNull(suggestionLists) || isEmpty(suggestionLists) ? null :
            (
              <MainContainerNew>
                <div>
                  <div>
                    {t("Suggestions For You")}
                  </div>
                  <div onClick={this.handleShowAll}>
                    {!this.state.showAll? t("See All >"):t("See Less >")}
                  </div>
                </div>
                {/* <SubContainerNew showAll={this.state.showAll}> */}
                {userDataShow.map((user, index) => (
                  user.request_status!="Pending"&&
                  <UserCard key={index}>
                    <div className='firstCard'>
                      <div className='image'>
                        <img
                          alt='avatar'
                          src={`${ImageUrl}/${user.profile_image}`}
                          onError={this.fallBack}
                        />
                      </div>
                      <div className='userDetails'>
                        <div className='userName'>{user.name}</div>
                        <div className='wellness'>
                          <WellnessInterestNew>
                            {user && user.wellness_interest ? user.wellness_interest.slice(0, 5).map((interest, index) => (
                              <div key={index}>
                                <img src={`${ImageUrl}/${interest.interest_image}`} />
                              </div>
                            )): null}
                          </WellnessInterestNew>
                        </div>
                      </div></div>
                    <div className='buttonContainer'>
                      {
                        user.request_status === "Pending" ? <div className="pending" onClick={() => this.cancelRequest(user.uid, user.request_status)}><span>{t('Pending')}</span></div> :
                          <div className="addbutton" onClick={() => this.sendRequest(user.uid, user.request_status)}><span>{t("Add Buddy")}</span></div>
                      }{user.request_status != "Pending" &&
                            <RemoveButton onClick={() => this.rejectRequest(user.uid)}>
                              <span>{t("Remove")}</span>
                            </RemoveButton>
                      }
                    </div>
                  </UserCard>
                ))}

              </MainContainerNew>
            )
        }
      </div>
    );
  }
}

SuggestionForYouSocial.propTypes = {
  suggestionList: PropTypes.array,
  history: PropTypes.object.isRequired,
  sendSuggestionFriendRequest: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  showAddBuddyPopUp: PropTypes.func,
  showAddBuddyPopUp2: PropTypes.func,
  t: PropTypes.func,
  getRejectFriendSuggestion: PropTypes.func,
}

const mapStateToProps = (state) => ({
  sentRequest: state.social.sentRequest
});
const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data)),
  getRejectFriendSuggestion: (data) => dispatch(getRejectFriendSuggestion(data))

})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestionForYouSocial));